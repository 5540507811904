import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openDrawer: true,
};

export const basicSlice = createSlice({
  name: "basic",
  initialState,
  reducers: {
    setDrawer: (state, action) => {
      state.openDrawer = action.payload;
    },
  },
});

export const { setDrawer } = basicSlice.actions;

export default basicSlice.reducer;
