import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { getJwtToken } from "../../storage/user";
import Layout from "../Layout";

const PrivateRoute = ({ element }) => {
  const isAuthenticated = getJwtToken();

  return isAuthenticated ? <Layout>{element}</Layout> : <Navigate to="/" />;
};

PrivateRoute.propTypes = {
  element: PropTypes.element.isRequired,
};

export default PrivateRoute;
