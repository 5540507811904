import { createContext, useContext, useState } from "react";

const OnBoardingContext = createContext();

// eslint-disable-next-line react/prop-types
const OnBoardingContextProvider = ({ children }) => {
  const [activeTab, setActiveTab] = useState(-1);

  const cancel = () => {
    activeTab !== 0 && setActiveTab((prevActiveTab) => prevActiveTab - 1);
  };

  // eslint-disable-next-line react/react-in-jsx-scope
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <OnBoardingContext.Provider value={{ activeTab, cancel, setActiveTab }}>{children}</OnBoardingContext.Provider>
  );
};

export default OnBoardingContextProvider;

export const useOnBoardingContext = () => useContext(OnBoardingContext);
