import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bluePrintsTableData: [],
};

export const blueprintsSlice = createSlice({
  name: "bluePrints",
  initialState,
  reducers: {
    setBlueprintsData: (state, action) => {
      state.bluePrintsTableData = action.payload;
    },
  },
});

export const { setBlueprintsData } = blueprintsSlice.actions;

export default blueprintsSlice.reducer;
