import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const Loader = () => (
  <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
    <CircularProgress color="primary" />
  </Box>
);

export default Loader;
