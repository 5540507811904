import { JWT } from "../utils/constants";

export const isUserLoggedIn = () => {
  const status = localStorage.getItem(JWT);
  if (status) {
    return JSON.parse(status);
  }
  return null;
};

export const getJwtToken = () => localStorage.getItem(JWT) || sessionStorage.getItem(JWT);

export const storeJwtToken = (token, rememberMe) => {
  if (rememberMe) {
    sessionStorage.removeItem(JWT);
    localStorage.setItem(JWT, token);
  } else {
    sessionStorage.setItem(JWT, token);
    localStorage.removeItem(JWT);
  }
};

export const removeToken = () => {
  sessionStorage.removeItem(JWT);
  localStorage.clear();
};

export const getLocalStorageValue = (value) => localStorage.getItem(value);
export const setLocalStorageValue = (value) => localStorage.setItem(value);

export const getActiveOnboardingTab = (user) => {
  const onboardingSteps = ["your_details", "select_plan", "terms_and_conditions", "payment_setup"];

  return onboardingSteps.findIndex((onboardingStep) => user[onboardingStep] === false) !== -1
    ? onboardingSteps.findIndex((onboardingStep) => user[onboardingStep] === false)
    : onboardingSteps.length;
};
