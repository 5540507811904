import { ThemeProvider } from "@mui/material";
import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./assets/styles/fonts.css";

import { AlertProvider } from "./components/common/Alert/AlertContext";
import Loader from "./components/route/Loader";
import PrivateRoute from "./components/route/PrivateRoute";
import {
  AdminUsersRoute,
  BlueprintsRoute,
  DashBoardRoute,
  DynamicBuilderEditRoute,
  DynamicBuilderRoute,
  ForgotPasswordRoute,
  OnBoardingRoute,
  ResetPasswordRoute,
  SignInRoute,
  SignUpRoute,
} from "./components/route/routes";
import theme from "./theme";

const BluePrints = lazy(() => import("./components/bluePrints"));
const Dashboard = lazy(() => import("./components/dashboard"));
const DynamicBuilder = lazy(() => import("./components/dynamicBuilder"));
const EditDynamicBuilder = lazy(() => import("./components/dynamicBuilder/EditDynamicBuilder"));
const ForgotPassword = lazy(() => import("./components/onBoarding/ForgotPassword"));
const NotFound = lazy(() => import("./components/route/NotFound"));
const OnBoarding = lazy(() => import("./components/onBoarding"));
const ResetPassword = lazy(() => import("./components/onBoarding/ResetPassword"));
const SignIn = lazy(() => import("./components/signIn"));
const SignUp = lazy(() => import("./components/onBoarding/SignUp"));
const Users = lazy(() => import("./components/users"));

const App = () => (
  <ThemeProvider theme={theme}>
    <AlertProvider>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Navigate to={SignInRoute} replace />} />
          <Route path={SignInRoute} element={<SignIn />} />
          <Route path={SignUpRoute} element={<SignUp />} />
          <Route path={OnBoardingRoute} element={<OnBoarding />} />
          <Route path={ForgotPasswordRoute} element={<ForgotPassword />} />
          <Route path={ResetPasswordRoute} element={<ResetPassword />} />
          <Route path="*" element={<NotFound />} />
          <Route path={DynamicBuilderRoute} element={<PrivateRoute element={<DynamicBuilder />} />} />
          <Route path={DynamicBuilderEditRoute} element={<PrivateRoute element={<EditDynamicBuilder />} />} />
          <Route path={DashBoardRoute} element={<PrivateRoute element={<Dashboard />} />} />
          <Route path={BlueprintsRoute} element={<PrivateRoute element={<BluePrints />} />} />
          <Route path={AdminUsersRoute} element={<PrivateRoute element={<Users />} />} />
        </Routes>
      </Suspense>
    </AlertProvider>
  </ThemeProvider>
);

export default App;
