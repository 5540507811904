import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  adminUsersTableData: [],
};

export const adminUsersSlice = createSlice({
  name: "adminUsers",
  initialState,
  reducers: {
    setAdminUsersData: (state, action) => {
      state.adminUsersTableData = action.payload;
    },
  },
});

export const { setAdminUsersData } = adminUsersSlice.actions;

export default adminUsersSlice.reducer;
