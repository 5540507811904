import { createSlice } from "@reduxjs/toolkit";
import { TABLE_HEADER_DROPDOWN_OPTIONS } from "../../constants/dynamicBuilder.constants";

const initialTabValue = {
  warmUp: false,
  cooldown: false,
  deload: false,
  weeks: [],
  warmUpText: "",
  cooldownText: "",
};

const getInitialValuesForEnabledVariables = () => TABLE_HEADER_DROPDOWN_OPTIONS.map((option) => option.value);

const initialState = {
  activeTab: 0,
  blueprintId: null,
  name: "",
  description: "",
  type: "",
  feedback: "default",
  tabs: [{ name: "Workout 1", ...initialTabValue }],
  duration: 1,
  isRowAdd: null,
  isRowRemove: null,
  exercises: [],
  enabledVariables: getInitialValuesForEnabledVariables(),
  columnsDef: [],
};
const updateColumnsDef = (columnsDef, workoutDetails) => {
  const updatedColumnsDef = [...columnsDef];

  workoutDetails.forEach((detail) => {
    TABLE_HEADER_DROPDOWN_OPTIONS.forEach((option) => {
      if (detail[option.value] !== null && detail[option.value] !== undefined) {
        const columnExists = updatedColumnsDef.find((column) => column.value === option.value);
        if (!columnExists) {
          updatedColumnsDef.push({
            displayName: option.displayName,
            value: option.value,
          });
        }
      }
    });
  });

  return updatedColumnsDef;
};

const dynamicBuilderSlice = createSlice({
  name: "dynamicBuilder",
  initialState,
  reducers: {
    addTab: (state, action) => {
      state.tabs.push({ ...initialTabValue, name: action.payload });
      state.activeTab = state.tabs.length - 1;
    },
    editTab: (state, action) => {
      const { index, newName } = action.payload;
      if (state.tabs[index]) {
        state.tabs[index].name = newName;
      }
    },
    removeTab: (state, action) => {
      const index = action.payload;
      state.tabs.splice(index, 1);
      if (state.activeTab >= state.tabs.length) {
        state.activeTab = Math.max(state.tabs.length - 1, 0);
      }
    },
    setAddRow: (state, action) => {
      state.isRowAdd = action.payload;
    },
    setRemoveRow: (state, action) => {
      state.isRowRemove = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setProgramName: (state, action) => {
      state.name = action.payload;
    },
    setProgramDescription: (state, action) => {
      state.description = action.payload;
    },
    setProgramType: (state, action) => {
      state.type = action.payload;
    },
    setProgramFeedback: (state, action) => {
      state.feedback = action.payload;
    },
    setDuration: (state, action) => {
      state.duration = action.payload;
    },
    setExercises: (state, action) => {
      state.exercises = action.payload;
    },
    toggleCooldown: (state) => {
      const currentTab = state.activeTab;
      state.tabs[currentTab].cooldown = !state.tabs[currentTab].cooldown;
    },
    setCooldownText: (state, action) => {
      state.tabs[state.activeTab].cooldownText = action.payload;
    },
    toggleWarmUp: (state) => {
      const currentTab = state.activeTab;
      state.tabs[currentTab].warmUp = !state.tabs[currentTab].warmUp;
    },
    setWarmUpText: (state, action) => {
      state.tabs[state.activeTab].warmUpText = action.payload;
    },
    toggleDeload: (state) => {
      const currentTab = state.activeTab;
      state.tabs[currentTab].deload = !state.tabs[currentTab].deload;
    },
    addTableData: (state, action) => {
      const { weekIndex, rowData } = action.payload;

      const newTabs = state.tabs.map((tab, index) => {
        if (index === state.activeTab) {
          const updatedWeeks = [...tab.weeks];
          if (weekIndex >= updatedWeeks.length) {
            updatedWeeks.length = weekIndex + 1;
          }

          updatedWeeks[weekIndex] = {
            week_number: weekIndex + 1,
            rows: rowData,
          };

          return { ...tab, weeks: updatedWeeks };
        }
        return tab;
      });

      return {
        ...state,
        tabs: newTabs,
      };
    },
    setEnabledVariables: (state, action) => {
      const { value } = action.payload;

      const currEnabledVariable = [...state.enabledVariables];

      const isValuePresent = currEnabledVariable.includes(value);

      const updatedEnabledVariables = isValuePresent
        ? currEnabledVariable.filter((variable) => variable !== value)
        : [...currEnabledVariable, value];

      state.enabledVariables = updatedEnabledVariables;
    },
    setProgramData: (state, action) => {
      const { id } = action.payload.blueprint;
      const { name, description, type, feedback, duration, workouts } = action.payload.blueprint.program;

      const formattedTabs = workouts?.map((workout) => ({
        ...initialTabValue,
        name: workout.name,
        // warmUp: workout.warm_up || false,
        // cooldown: workout.cool_down || false,
        warmUp: workout?.warm_up !== "" ? true : false,
        cooldown: workout.cool_down || false,
        ...(workout?.warm_up && { warmUpText: workout?.warm_up }),
        ...(workout?.cool_down && { cooldownText: workout?.cool_down }),
        weeks: workout.weeks.map((week) => ({
          week_number: week.week_number,
          rows: week.workoutDetails.map((row) => {
            const isParent =
              week.workoutDetails.findIndex((workoutRow) => workoutRow.linked_to === row.row_id) !== -1 ? true : false;
            return { ...row, id: row.row_id, isParent };
          }),
        })),
      }));

      const allWorkoutDetails = workouts.flatMap((workout) => workout.weeks.flatMap((week) => week.workoutDetails));
      state.columnsDef = updateColumnsDef([], allWorkoutDetails);

      state.blueprintId = id;
      state.name = name;
      state.description = description;
      state.type = type;
      state.feedback = feedback;
      state.duration = duration;
      state.tabs = formattedTabs;
      state.activeTab = 0;
    },
    resetDynamicBuilder: () => initialState,
  },
});

export const {
  addTab,
  editTab,
  removeTab,
  setActiveTab,
  toggleCooldown,
  toggleWarmUp,
  toggleDeload,
  setDuration,
  setProgramName,
  setProgramDescription,
  setProgramType,
  setProgramFeedback,
  setCooldownText,
  setWarmUpText,
  setExercises,
  addTableData,
  setAddRow,
  setRemoveRow,
  setEnabledVariables,
  setProgramData,
  resetDynamicBuilder,
} = dynamicBuilderSlice.actions;

export default dynamicBuilderSlice.reducer;
