import { createTheme } from "@mui/material";
import componentStyleOverrides from "./componentStyleOverride";

const theme = createTheme({
  palette: {
    primary: {
      main: "#013D29",
    },
    black: {
      light: "#212121",
    },
    gray: {
      light: "#8C97A1",
      dark: "#8E9094",
      lighter: "#F9FAFB",
    },
    white: {
      light: "#EAEDF1",
    },
    red: {
      dark: "#8F1010",
    },
    sky: {
      light: "#009FD1",
    },
    lemon: "#BBF247",
    danger: "#D32F2F",
    charcoal: "#344054",
  },
  typography: {
    fontFamily: "PlusJakartaSans, Arial, sans-serif",
    body1: {
      fontSize: "14px",
    },
  },
  shape: {
    borderRadius: 12,
  },
});

theme.components = componentStyleOverrides(theme);

export default theme;
