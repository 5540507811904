export const AdminFormsRoute = "/admin/forms";
export const AdminLandingPageRoute = "/admin/landing-page";
export const AdminServicesRoute = "/admin/services";
export const AdminSettingsRoute = "/admin/settings";
export const AdminUsersRoute = "/admin/users";
export const AssessmentRoute = "/assessments";
export const BlueprintsRoute = "/blueprints";
export const CalenderRoute = "/calender";
export const ClientsRoute = "/clients";
export const DashBoardRoute = "/dashboard";
export const DynamicBuilderRoute = "/dynamic-builder";
export const DynamicBuilderEditRoute = "/dynamic-builder/:programId";
export const ForgotPasswordRoute = "/forgot-password";
export const HomeRoute = "/";
export const MarketHubRoute = "/market-hub";
export const MessageRoute = "/messages";
export const OnBoardingRoute = "/onboarding";
export const ResetPasswordRoute = "/reset-password";
export const SignInRoute = "/sign-in";
export const SignUpRoute = "/sign-up";
export const TasksRoute = "/tasks";
