import AdminSettingIcon from "../../../assets/images/adminSettingIcon.svg";
import AssesmentsIcon from "../../../assets/images/assesmentsIcon.svg";
import CalendarIcon from "../../../assets/images/calendarIcon.svg";
import ClientsIcon from "../../../assets/images/clientsIcon.svg";
import DashboardIcon from "../../../assets/images/dashboardIcon.svg";
import MarketHubIcon from "../../../assets/images/marketHubIcon.svg";
import MessagesIcon from "../../../assets/images/messagesIcon.svg";
import ProgramManagerIcon from "../../../assets/images/programManagerIcon.svg";
import TasksIcon from "../../../assets/images/tasksIcon.svg";
import {
  AdminFormsRoute,
  AdminLandingPageRoute,
  AdminServicesRoute,
  AdminSettingsRoute,
  AdminUsersRoute,
  AssessmentRoute,
  BlueprintsRoute,
  CalenderRoute,
  ClientsRoute,
  DashBoardRoute,
  DynamicBuilderRoute,
  MarketHubRoute,
  MessageRoute,
} from "../../route/routes";

const sidebarLinks = [
  { text: "Dashboard", icon: DashboardIcon, path: DashBoardRoute },
  { text: "Calendar", icon: CalendarIcon, path: CalenderRoute },
  { text: "Clients", icon: ClientsIcon, path: ClientsRoute },
  { text: "Messages", icon: MessagesIcon, path: MessageRoute },
  {
    text: "Program Manager",
    icon: ProgramManagerIcon,
    nested: [
      { text: "Dynamic Builder", path: DynamicBuilderRoute },
      { text: "Blueprints", path: BlueprintsRoute },
    ],
  },
  { text: "Tasks", icon: TasksIcon, path: "/tasks" },
  { text: "Assessments", icon: AssesmentsIcon, path: AssessmentRoute },
  { text: "Market Hub", icon: MarketHubIcon, path: MarketHubRoute },
];
const sidebarAdminLinks = [
  {
    text: "Admin ",
    icon: AdminSettingIcon,
    nested: [
      { text: "Forms", path: AdminFormsRoute },
      { text: "Landing Page", path: AdminLandingPageRoute },
      { text: "Services", path: AdminServicesRoute },
      { text: "Users", path: AdminUsersRoute },
      { text: "Settings", path: AdminSettingsRoute },
    ],
  },
];

export { sidebarAdminLinks, sidebarLinks };
