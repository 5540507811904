import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  description: "",
  type: "",
  priority: "",
  selectedFormField: {},
  dynamicFields: [],
  activeTabIndex: 0,
  viewOnly: false,
};

export const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setFormName: (state, action) => {
      state.name = action.payload;
    },
    setFormDescription: (state, action) => {
      state.description = action.payload;
    },
    setFormType: (state, action) => {
      state.type = action.payload;
    },
    setFormPriority: (state, action) => {
      state.priority = action.payload;
    },
    setSelectedFormField: (state, action) => {
      state.selectedFormField = action.payload;
    },
    setDynamicFormFields: (state, action) => {
      const newField = action.payload;
      const previousDynamicFields = state.dynamicFields;

      return {
        ...state,
        dynamicFields: [...previousDynamicFields, newField],
      };
    },
    updateDynamicFormField: (state, action) => {
      const { fieldTag, updatedField } = action.payload;
      const previousDynamicFields = state.dynamicFields;

      const updatedDynamicFields = previousDynamicFields.map((dynamicField) =>
        dynamicField.fieldTag === fieldTag ? updatedField : dynamicField
      );

      return {
        ...state,
        dynamicFields: updatedDynamicFields,
      };
    },
    deleteDynamicFormField: (state, action) => {
      const previousDynamicFields = state.dynamicFields;
      const deleteFieldTag = action.payload;

      const updatedDynamicFields = previousDynamicFields.filter(
        (dynamicField) => dynamicField.fieldTag !== deleteFieldTag
      );

      return {
        ...state,
        dynamicFields: updatedDynamicFields,
      };
    },
    setActiveTabIndex: (state, action) => {
      state.activeTabIndex = action.payload;
    },
    setFreeTextField: (state, action) => {
      const previousDynamicFields = state.dynamicFields;
      const { fieldTag, defaultValue } = action.payload;

      const updatedDynamicFields = previousDynamicFields.map((dynamicField) =>
        dynamicField.fieldTag === fieldTag ? { ...dynamicField, defaultValue } : dynamicField
      );

      return {
        ...state,
        dynamicFields: updatedDynamicFields,
      };
    },
    setFormData: (state, action) => {
      const { name, description, type, priority, view_only } = action.payload.formData;

      state.name = name;
      state.description = description;
      state.type = type;
      state.priority = priority;
      state.viewOnly = view_only;
    },
    resetDynamicAndSelectedFormFields: (state) => ({ ...state, selectedFormField: {}, dynamicFields: [] }),
    resetForm: () => ({ ...initialState }),
  },
});

export const {
  setFormName,
  setFormDescription,
  setFormType,
  setFormPriority,
  setSelectedFormField,
  setDynamicFormFields,
  updateDynamicFormField,
  deleteDynamicFormField,
  setActiveTabIndex,
  setFreeTextField,
  setFormData,
  resetDynamicAndSelectedFormFields,
  resetForm,
} = formSlice.actions;

export default formSlice.reducer;
