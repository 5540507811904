import { createSlice } from "@reduxjs/toolkit";
import { TABLE_HEADER_DROPDOWN_OPTIONS } from "../../constants/dynamicBuilder.constants";

const initialTabValue = {
  warmUp: false,
  cooldown: false,
  deload: false,
  weeks: [],
};

const getInitialValuesForEnabledVariables = () => TABLE_HEADER_DROPDOWN_OPTIONS.map((option) => option.value);

const initialState = {
  activeTab: 0,
  tabs: [{ name: "Workout 1", ...initialTabValue }],
  duration: 1,
  isRowAdd: null,
  isRowRemove: null,
  exercises: [],
  enabledVariables: getInitialValuesForEnabledVariables(),
};

const dynamicBuilderSlice = createSlice({
  name: "dynamicBuilder",
  initialState,
  reducers: {
    addTab: (state, action) => {
      state.tabs.push({ ...initialTabValue, name: action.payload });
      state.activeTab = state.tabs.length - 1;
    },
    editTab: (state, action) => {
      const { index, newName } = action.payload;
      if (state.tabs[index]) {
        state.tabs[index].name = newName;
      }
    },
    removeTab: (state, action) => {
      const index = action.payload;
      state.tabs.splice(index, 1);
      if (state.activeTab >= state.tabs.length) {
        state.activeTab = Math.max(state.tabs.length - 1, 0);
      }
    },
    setAddRow: (state, action) => {
      state.isRowAdd = action.payload;
    },
    setRemoveRow: (state, action) => {
      state.isRowRemove = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setDuration: (state, action) => {
      state.duration = action.payload;
    },
    setExercises: (state, action) => {
      state.exercises = action.payload;
    },
    toggleCooldown: (state) => {
      const currentTab = state.activeTab;
      state.tabs[currentTab].cooldown = !state.tabs[currentTab].cooldown;
    },
    setCooldownText: (state, action) => {
      state.tabs[state.activeTab].cooldown = action.payload;
    },
    toggleWarmUp: (state) => {
      const currentTab = state.activeTab;
      state.tabs[currentTab].warmUp = !state.tabs[currentTab].warmUp;
    },
    setWarmUpText: (state, action) => {
      state.tabs[state.activeTab].warmUpText = action.payload;
    },
    toggleDeload: (state) => {
      const currentTab = state.activeTab;
      state.tabs[currentTab].deload = !state.tabs[currentTab].deload;
    },
    addTableData: (state, action) => {
      const { weekIndex, rowData } = action.payload;

      const newTabs = state.tabs.map((tab, index) => {
        if (index === state.activeTab) {
          const updatedWeeks = [...tab.weeks];
          if (weekIndex >= updatedWeeks.length) {
            updatedWeeks.length = weekIndex + 1;
          }

          updatedWeeks[weekIndex] = {
            week_number: weekIndex + 1,
            rows: rowData,
          };

          return { ...tab, weeks: updatedWeeks };
        }
        return tab;
      });

      return {
        ...state,
        tabs: newTabs,
      };
    },
    setEnabledVariables: (state, action) => {
      const { value } = action.payload;

      const currEnabledVariable = [...state.enabledVariables];

      const isValuePresent = currEnabledVariable.includes(value);

      const updatedEnabledVariables = isValuePresent
        ? currEnabledVariable.filter((variable) => variable !== value)
        : [...currEnabledVariable, value];

      state.enabledVariables = updatedEnabledVariables;
    },
    setProgramData: (state, action) => {
      const { duration, workouts } = action.payload.program.program;
      console.log(duration, workouts);

      const formattedTabs = workouts?.map((workout) => ({
        ...initialTabValue,
        name: workout.name,
        warmUp: workout.warm_up || false,
        cooldown: workout.cool_down || false,
        weeks: workout.weeks || [],
      }));

      state.duration = duration;
      state.tabs = formattedTabs;
      state.activeTab = 0; // Reset to the first tab
    },
  },
});

export const {
  addTab,
  editTab,
  removeTab,
  setActiveTab,
  toggleCooldown,
  toggleWarmUp,
  toggleDeload,
  setDuration,
  setCooldownText,
  setWarmUpText,
  setExercises,
  addTableData,
  setAddRow,
  setRemoveRow,
  setEnabledVariables,
  setProgramData,
} = dynamicBuilderSlice.actions;

export default dynamicBuilderSlice.reducer;
