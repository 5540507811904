import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  role: "",
  isVerified: false,
  yourDetails: false,
  selectPlan: false,
  termsAndConditions: false,
  paymentSetup: false,
};

export const userSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      const {
        id,
        first_name,
        last_name,
        email,
        role,
        is_verified,
        your_details,
        select_plan,
        terms_and_conditions,
        payment_setup,
      } = action.payload;

      state.id = id;
      state.firstName = first_name;
      state.lastName = last_name;
      state.email = email;
      state.role = role;
      state.isVerified = is_verified;
      state.yourDetails = your_details;
      state.selectPlan = select_plan;
      state.termsAndConditions = terms_and_conditions;
      state.paymentSetup = payment_setup;
    },
  },
});

export const { setUserDetails } = userSlice.actions;

export default userSlice.reducer;
