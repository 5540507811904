import { configureStore } from "@reduxjs/toolkit";

import adminUsersSlice from "./features/adminUsersSlice";
import basicSlice from "./features/basicSlice";
import blueprintsSlice from "./features/blueprintsSlice";
import dynamicBuilderSlice from "./features/dynamicBuilderSlice";
import userSlice from "./features/userSlice";

export const store = configureStore({
  reducer: {
    basic: basicSlice,
    bluePrints: blueprintsSlice,
    builder: dynamicBuilderSlice,
    user: userSlice,
    adminUsers: adminUsersSlice,
  },
});
